import React from 'react'
import Link from 'gatsby-link'

import './style.scss'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'

const ContactPage = () => (
  <div id="submit-page">
    <div className="content-wrap">
      <section className="contact-section page-title">
        <div className="container">
          <h2>Contact</h2>
          <p>Drop us a line.</p>
          <p>
            Whether you'd like to give feeback, make a suggestion or just say hi
            we're here!
          </p>
          <p>
            Reach out to us over live chat or send us an email at
            team@borrowmellow.com
          </p>
        </div>
      </section>
    </div>
  </div>
)

export default ({ location }) => (
  <Layout navbar="main" location={location} showBeacon={true}>
    <SEO title="Contact Mellow" />
    <ContactPage />
  </Layout>
)
